import { NgModule }            from '@angular/core'
import { BrowserModule }       from '@angular/platform-browser'
import { FormsModule }         from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { HttpCsrfTokenInterceptor } from '../src/http-csrf-token.interceptor'
import { RoutingModule }            from './routing.module'

import { AppComponent } from './app.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { LoadingComponent } from './components/loading/loading.component'
import { HeroComponent } from './components/hero/hero.component'
import { ConcernsComponent } from './components/concerns/concerns.component'
import { AboutComponent } from './components/about/about.component'
import { ServicesComponent } from './components/services/services.component'
import { MyopiaComponent } from './components/myopia/myopia.component'
import { DryEyeComponent } from './components/dry-eye/dry-eye.component'
import { GlaucomaComponent } from './components/glaucoma/glaucoma.component'
import { PrimaryEyeComponent } from './components/primary-eye/primary-eye.component'
import { LocationComponent } from './components/location/location.component'

@NgModule({
	declarations: [
		AppComponent,
		AccordionComponent,
		LoadingComponent,
		HeroComponent,
		ConcernsComponent,
		AboutComponent,
		ServicesComponent,
		MyopiaComponent,
		DryEyeComponent,
		GlaucomaComponent,
		PrimaryEyeComponent,
		LocationComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		FormsModule,
		RecaptchaV3Module,
		RoutingModule,
	],
	providers: [{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpCsrfTokenInterceptor,
		multi: true,
	}, {
		provide: RECAPTCHA_V3_SITE_KEY,
		useValue: '6LdFOcwUAAAAAI1TsgrArIMRsq7FPY21S8SQ8ise'
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }
