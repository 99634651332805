import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'dsec_app',
	templateUrl: './app.component.slim',
	styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
	currYear: number

	ngOnInit(): void {
		this.currYear = new Date().getFullYear()
	}
}
