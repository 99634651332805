import { Component, ViewChild, AfterViewChecked, OnDestroy  } from '@angular/core'
import { NgForm, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { Subscription } from 'rxjs'
import { ReCaptchaV3Service } from 'ng-recaptcha'

@Component({
	selector: 'hero',
	templateUrl: './hero.component.slim',
	styleUrls: ['./hero.component.sass']
})
export class HeroComponent implements AfterViewChecked, OnDestroy {
	private subCaptcha: Subscription
	private subHttp: Subscription

	apptFormData = {
		apptName: '',
		apptPhone: '',
		apptEmail: '',
		apptCurrPatient: 'No',
		apptAge: '',
		apptReason: '',
		apptComments: ''
	}

	apptLoading: boolean = false
	apptHttpResult: any = {}

	@ViewChild('apptForm', {static: false}) apptForm: NgForm

	constructor(
		private http: HttpClient,
		private recaptchaV3Service: ReCaptchaV3Service
	) { }

	ngAfterViewChecked(): void {
		if (!this.apptForm.control.controls.apptPhone) { return }
		this.apptForm.controls.apptPhone.setValidators([
			Validators.required,
			(currentControl: AbstractControl): ValidationErrors | null => {
				var regex = /^[0-9\-\(\)\s\\\/\+\*#;,]{10,}$/i
				var value = currentControl.value
				if (value && !regex.test(value)) { return { invalid: true } }
				return null
			}
		])
	}

	ngOnDestroy(): void {
		if (this.subCaptcha) { this.subCaptcha.unsubscribe() }
		if (this.subHttp) { this.subHttp.unsubscribe() }
	}

	submitAppt(): void {
		this.apptForm.control.markAllAsTouched()
		if (!this.apptForm.valid) { return }

		this.apptLoading = true
		this.apptHttpResult = {}

		this.subCaptcha = this.recaptchaV3Service.execute('apptRequest')
		.subscribe(token => {
			var payload = this.apptForm.value
			payload['recaptchaToken'] = token

			this.subHttp = this.http.post('appt_mail.json', payload)
			.subscribe(result => {
				this.apptLoading = false
				this.apptHttpResult = result
			}, error => {
				this.apptLoading = false
				this.apptHttpResult = { errors: [`HTTP ${error.status} ${error.statusText}`] }
			})

		})
	}
}
