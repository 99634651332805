import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [

];

@NgModule({
	imports: [ RouterModule.forRoot(routes, routerOptions) ],
	exports: [ RouterModule ]
})
export class RoutingModule { }
