import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'location',
	templateUrl: './location.component.slim',
	styleUrls: ['./location.component.sass']
})
export class LocationComponent implements OnInit {
	ngOnInit(): void { }
}
