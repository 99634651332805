import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class HttpCsrfTokenInterceptor implements HttpInterceptor {

	private csrfToken: string

	constructor(@Inject(DOCUMENT) private doc: any) {
		this.csrfToken = doc.querySelector('meta[name=csrf-token]').getAttribute('content');
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		};

		if (this.csrfToken) {
			headersConfig['X-CSRF-TOKEN'] = this.csrfToken
		}

		const request = req.clone({ setHeaders: headersConfig });
		return next.handle(request);
	}
}
