import '../src/polyfills'

import { enableProdMode }     from '@angular/core'
import { platformBrowser }    from '@angular/platform-browser'
import { AppModuleNgFactory } from './app.module.ngfactory'

if (process.env.RAILS_ENV === 'production') { enableProdMode() }

document.addEventListener('DOMContentLoaded', () => {
	platformBrowser().bootstrapModuleFactory(AppModuleNgFactory)
})
