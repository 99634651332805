/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// ES2015 symbol capabilities
import 'core-js/modules/es.symbol';

// ES2015 function capabilities
import 'core-js/modules/es.function.bind';
import 'core-js/modules/es.function.name';
import 'core-js/modules/es.function.has-instance';

// ES2015 object capabilities
import 'core-js/modules/es.object.create';
import 'core-js/modules/es.object.define-property';
import 'core-js/modules/es.object.define-properties';
import 'core-js/modules/es.object.get-own-property-descriptor';
import 'core-js/modules/es.object.get-prototype-of';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.object.get-own-property-names';
import 'core-js/modules/es.object.freeze';
import 'core-js/modules/es.object.seal';
import 'core-js/modules/es.object.prevent-extensions';
import 'core-js/modules/es.object.is-frozen';
import 'core-js/modules/es.object.is-sealed';
import 'core-js/modules/es.object.is-extensible';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.is';
import 'core-js/modules/es.object.set-prototype-of';
import 'core-js/modules/es.object.to-string';

// ES2015 array capabilities
import 'core-js/modules/es.array.is-array';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.array.of';
import 'core-js/modules/es.array.join';
import 'core-js/modules/es.array.slice';
import 'core-js/modules/es.array.sort';
import 'core-js/modules/es.array.for-each';
import 'core-js/modules/es.array.map';
import 'core-js/modules/es.array.filter';
import 'core-js/modules/es.array.some';
import 'core-js/modules/es.array.every';
import 'core-js/modules/es.array.reduce';
import 'core-js/modules/es.array.reduce-right';
import 'core-js/modules/es.array.index-of';
import 'core-js/modules/es.array.last-index-of';
import 'core-js/modules/es.array.copy-within';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';
import 'core-js/modules/es.array.iterator';

// ES2015 string capabilities
import 'core-js/modules/es.string.from-code-point';
import 'core-js/modules/es.string.raw';
import 'core-js/modules/es.string.trim';
import 'core-js/modules/es.string.iterator';
import 'core-js/modules/es.string.code-point-at';
import 'core-js/modules/es.string.ends-with';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.repeat';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.string.anchor';
import 'core-js/modules/es.string.big';
import 'core-js/modules/es.string.blink';
import 'core-js/modules/es.string.bold';
import 'core-js/modules/es.string.fixed';
import 'core-js/modules/es.string.fontcolor';
import 'core-js/modules/es.string.fontsize';
import 'core-js/modules/es.string.italics';
import 'core-js/modules/es.string.link';
import 'core-js/modules/es.string.small';
import 'core-js/modules/es.string.strike';
import 'core-js/modules/es.string.sub';
import 'core-js/modules/es.string.sup';

import 'core-js/modules/es.parse-int';
import 'core-js/modules/es.parse-float';

import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/date';
import 'core-js/es/regexp';

import 'core-js/modules/es.map';
import 'core-js/modules/es.weak-map';
import 'core-js/modules/es.set';
import 'core-js/modules/web.dom-collections.iterator';
import 'core-js/modules/es.promise';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** Evergreen browsers require these. **/
import 'core-js/es/reflect';
import 'core-js/proposals/reflect-metadata';


/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';
// import 'zone.js/dist/long-stack-trace-zone' // async stack traces with zone.js



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
