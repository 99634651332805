import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core'

@Component({
	selector: 'accordion',
	templateUrl: './accordion.component.slim',
	styleUrls: ['./accordion.component.sass']
})
export class AccordionComponent implements OnInit {
	expanded: boolean = false
	titleAttr: string
	accNavClasses: object

	@ViewChild('accHead', {static: false}) accHead: ElementRef

	@Input() accType: string = 'nav'
	@Input() accTitle: string
	@Input() accWhite: boolean
	@Input() accCollapse: boolean = true
	@Input() accExpanded: boolean = false

	ngOnInit(): void {
		this.expanded = this.accExpanded
		this.setTittleAttr()

		this.accNavClasses = {
			'head':      true,
			'nav':       (this.accType == 'nav') && !this.accWhite,
			'nav-white': (this.accType == 'nav') && this.accWhite,

			'btn':       (this.accType == 'btn') && !this.accWhite,
			'btn-white': (this.accType == 'btn') && this.accWhite
		}	
	}

	toggleContent(): void {
		if (this.expanded) {
			this.accNavClasses['head'] = true
			this.accNavClasses['head-exp'] = false

			this.accHead.nativeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' })

		} else {
			this.accNavClasses['head'] = false
			this.accNavClasses['head-exp'] = true
		}

		this.expanded = !this.expanded
		this.setTittleAttr()
	}

	private setTittleAttr() {
		if (this.expanded) {
			this.titleAttr = `Collapse: ${ this.accTitle }`
		} else {
			this.titleAttr = `Expand: ${ this.accTitle }`
		}
	}
}
